import { plugin, defaultConfig, createInput } from '@formkit/vue';
import FormKitSignature from '@/components/FormKit/FormKitSignature.vue';
import FormkitPhone from '@/components/FormKit/FormKitPhone.vue';
import FormKitRate from '@/components/FormKit/FormKitRate.vue';
import { generateClasses } from '@formkit/themes';
import FormKitToggleButton from '@/components/FormKit/FormKitToggleButton.vue';
import i18n from '@/locales/index';
import { ru, en, uk, pl, es, cs, de, nl, hu, bg, sk } from '@formkit/i18n'

const formKitConfig = defaultConfig({
  inputs: {
    signature: createInput(FormKitSignature),
    phoneNumber: createInput(FormkitPhone),
    tgb: createInput(FormKitToggleButton, {
      props: ['options']
    }),
    rate: createInput(FormKitRate, {
      props: ['count', 'icon', 'iconActive'],
    }),
  },
  config: {
    classes: generateClasses({
      rate: {
        inner: 'formkit-inner-rate',
      },
      tgb: {
        inner: 'formkit-inner-rate',
      },
    }),
  },
  locales: { ru, en, uk, pl, es, cs, de, nl, hu, bg, sk },
  locale: i18n.global.locale.value
});
console.log(i18n.global.locale.value)
export { plugin, formKitConfig };